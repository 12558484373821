import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { OverlayColor } from '@app/core/enum/loader.enum';

@Component({
  selector: 'aladdin-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  @Input() isLoading?: boolean;
  @Input() isBlockLineLoader?: boolean;
  @Input() title?: string;
  @Input() fullScreen?: boolean;
  @Input() overlay?: OverlayColor = OverlayColor.WHITE;

  balls = [1, 2, 3, 4];
  dots = [1, 2, 3];

  @HostListener('wheel', ['$event'])
  handleWheelEvent(event: WheelEvent) {
    if (this.fullScreen) {
      event.preventDefault();
    }
  }
}
